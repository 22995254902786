import React from 'react'
import styled from 'styled-components'
import { ColorRing } from 'react-loader-spinner'

const Loading = ({ height="120", width="120" }) => {
    return (
        <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
            <ColorRing
                visible={true}
                height={height}
                width={width}
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#91a8cb', '#91a8cb', '#91a8cb', '#91a8cb', '#91a8cb']}
            />
        </div>
    )
}

export default Loading
