import React from 'react';
import styled from 'styled-components';
import menu from '../../public/img/menu.svg';


const BurgerMenu = ({ onClick }) => {
    return (
        <Img src={menu} onClick={onClick}/>
    );
};

const Img = styled.img`
    width: 32px;
    height: 32px;
    cursor: pointer;

    @media (min-width: 768px) {
        display: none;
    }
`;

export default BurgerMenu;