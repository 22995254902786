import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import 'ag-grid-enterprise'

const Table = ({ rowData, onFilterChanged, gridRef }) => {

    const [getRowData, setRowData] = useState(rowData)

    const gridOptions = {
        columnDefs: [
            {
                headerName: 'Ticker',
                field: 'Ticker',
                filter: true,
                sortable: true,
                maxWidth: 200,
            },
            {
                headerName: 'Strategy',
                field: 'Strategy',
                filter: true,
                sortable: true,
                maxWidth: 200,
            },
            {
                headerName: 'Result',
                field: 'Result',
                filter: true,
                sortable: true,
                maxWidth: 200,
            },
            {
                headerName: 'Cierre',
                field: 'Tipo de cierre',
                filter: true,
                sortable: true,
                maxWidth: 200,
            },
            {
                headerName: 'PnL',
                field: 'PnL',
                filter: true,
                sortable: true,
                maxWidth: 200,
            },
            {
                headerName: 'RR',
                field: 'RR',
                filter: true,
                sortable: true,
                maxWidth: 200,
            },
            {
                headerName: 'TF',
                field: 'Timeframe',
                filter: true,
                sortable: true,
                maxWidth: 200,
            },
            {
                headerName: 'Max Target',
                field: 'Max Target hit',
                filter: true,
                sortable: true,
                maxWidth: 200,
            },
            {
                headerName: 'Time',
                field: 'timestamp',
                filter: true,
                sortable: true,
                maxWidth: 200,
                sort: 'desc',
            },
        ],
        defaultColDef: {
            sortable: true,
            filter: 'agTextColumnFilter',
            editable: true,
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
        },
        suppressRowClickSelection: true,
        rowSelection: 'multiple',
        animateRows: true,
        pagination: false,
        paginationPageSize: 100,
        rowModelType: 'clientSide',
    }

    const autoSizeStrategy = {
        type: 'fitGridWidth',
        defaultMaxWidth: 130,
    }

    useEffect(() => {
        const updatedRowData = rowData;
        setRowData(updatedRowData);
    }, [rowData]);

    return (
        <div
            className="ag-theme-quartz-dark"
            style={{ height: '100%', width: '100%', minHeight: '500px'}}
        >
            <AgGridReact
                autoSizeStrategy={autoSizeStrategy}
                rowData={getRowData}
                gridOptions={gridOptions}
                ref={gridRef}
                onFilterChanged={onFilterChanged}
            />
        </div>
    )
}

export default Table
