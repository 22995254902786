import Signals from './components/Pages/Signals';
import Calculator from './components/Pages/Calculator';
import Options from './components/Pages/Options';
import Chart from './components/Pages/Chart';
import Settings from './components/Pages/Settings';
import Portfolio from './components/Pages/Portfolio';
import Scanner from './components/Pages/Scanner';
import Stocks from './components/Pages/Stocks';
import Auth from './components/Pages/Auth';
const routes = [
    {
        path: '/auth',
        component: Auth,
    },
    {
        path: '/signals',
        component: Signals,
    },
    {
        path: '/calculator',
        component: Calculator,
    },
    {
        path: '/options',
        component: Options,
    },
    {
        path: '/chart',
        component: Chart,
    },
    {
        path: '/settings',
        component: Settings,
    },
    {
        path: '/portfolio',
        component: Portfolio,
    },
    {
        path: '/scanner',
        component: Scanner,
    },
    {
        path: '/stocks',
        component: Stocks,
    },
    // {
    //     path: '*',
    //     component: NotFound,
    // },
]

export default routes;