import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Dashboard from '../Pages/Dashboard'
import { Route, Routes, Navigate } from 'react-router-dom'
import SidebarMenu from '../Section/Sidebar'
import NotFound from '../Pages/NotFound'
import HeaderMenu from '../common/HeaderMenu.jsx'
import useAuth from '../hooks/useAuth'
import Auth from '../Pages/Auth'
import config from '../../config.js'

const Layout = ({ options, gridRef, handleFilterChanged, rowData }) => {
    const { isLoggedIn, isAuthenticating } = useAuth()
    const [show, setShow] = useState(false)
    const [width, setWidth] = useState(window.innerWidth)

    window.addEventListener('resize', () => {
        setWidth(window.innerWidth)
    })

    const handleSideBar = (e) => {
        e.preventDefault()
        setShow(!show)
    }

    useEffect(() => {
        if (!isAuthenticating && !isLoggedIn) {
            console.log('Redirecting to /auth')
            console.log('isLoggedIn', isLoggedIn)
            // Redirige al usuario si no está autenticado
            // window.location.href = "/auth";
        }
    }, [isAuthenticating, isLoggedIn])

    return (
        <>
            {isLoggedIn ? (
                <>
                    <GridContainer>
                        <Sidebar show={show}>
                            <ShowMobile width={width}>
                                <HeaderMenu onClick={handleSideBar} />
                            </ShowMobile>
                            <SidebarMenu
                                show={show}
                                handleSideBar={handleSideBar}
                            />
                        </Sidebar>
                        <Content>
                            {/* <Navbar></Navbar> */}
                            <MainContent>
                                <Main>
                                    <Routes>
                                        <Route
                                            path="/dashboard"
                                            element={
                                                isLoggedIn ? (
                                                    <Dashboard
                                                        options={options}
                                                        gridRef={gridRef}
                                                        handleFilterChanged={
                                                            handleFilterChanged
                                                        }
                                                        rowData={rowData}
                                                    />
                                                ) : (
                                                    <Navigate
                                                        replace
                                                        to="/auth"
                                                    />
                                                )
                                            }
                                        />
                                        <Route
                                            path="*"
                                            element={<NotFound />}
                                        />
                                        <Route
                                            path="/"
                                            element={
                                                <Navigate
                                                    replace
                                                    to="/dashboard"
                                                />
                                            }
                                        />
                                        {config.map((route, index) => (
                                            <Route
                                                key={index}
                                                path={route.path}
                                                element={
                                                    isLoggedIn ? (
                                                        <route.component />
                                                    ) : (
                                                        <Navigate
                                                            replace
                                                            to="/auth"
                                                        />
                                                    )
                                                }
                                            />
                                        ))}
                                    </Routes>
                                </Main>
                                {/* <RightSidebar></RightSidebar> */}
                            </MainContent>
                        </Content>
                    </GridContainer>
                </>
            ) : (
                <Auth />
            )}
        </>
    )
}

const gap = '8px'
const backgroundColor = '#1A2534'
const layoutColor = '#233245'
const borderRadius = '10px'

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-template-rows: 1fr;
    background-color: ${backgroundColor};
    padding: 10px;
    gap: ${gap};
    width: 100vw;
    height: 100vh;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

const ShowMobile = styled.div`
    display: none;

    @media (max-width: 768px) {
        display: ${({ width }) => (width <= 768 ? 'block' : 'none')};
    }
`

const Sidebar = styled.div`
    display: flex;
    background-color: ${layoutColor};
    height: calc(100vh - 20px);
    width: 100%;
    border-radius: ${borderRadius};

    @media (max-width: 768px) {
        height: 50px;
        margin: 0 20px;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 40px);
        padding-right: 20px;
    }
`

const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100%;
    width: 100%;
    gap: ${gap};
    border-radius: ${borderRadius};
    padding: 0 10px;
`

// const Navbar = styled.div`
//     display: flex;
//     background-color: ${layoutColor};
//     height: 100%;
//     width: 100%;
//     border-radius: ${borderRadius};
// `

const MainContent = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100%;
    width: 100%;
    gap: ${gap};
    border-radius: ${borderRadius};
`

const Main = styled.div`
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 20px);
    border-radius: ${borderRadius};
    overflow-x: auto;
    overflow-y: auto;
    gap: ${gap};
    padding: 0 20px;
`

// const RightSidebar = styled.div`
//     display: flex;
//     background-color: ${layoutColor};
//     height: 100%;
//     width: 100%;
//     border-radius: ${borderRadius};
// `

export default Layout
