import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import OptionsTable from '../common/OptionsTable'
import Loading from '../common/Loading'
import content from '../../content/content'

const stockStatsId = content.stockStatsId

const Options = () => {
    const [stats, setStats] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [optionsTicker, setOptionsTicker] = useState(
        localStorage.getItem('optionsTicker') || 'GGAL',
    )
    const [optionsData, setOptionsData] = useState([])
    const [stockData, setStockData] = useState([])
    const [stockPrice, setStockPrice] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        try {
            axios
                .get(
                    `https://us-central1-trading-alerts-c1dba.cloudfunctions.net/app/options/${optionsTicker}`,
                )
                .then((response) => {
                    setOptionsData(response.data)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                })

            axios
                .get(
                    'https://us-central1-trading-alerts-c1dba.cloudfunctions.net/app/cotizaciones/',
                )
                .then((response) => {
                    setStockData(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }, [optionsTicker])

    useEffect(() => {
        try {
            if (stockData.titulos) {
                const tickerData = stockData.titulos.filter(
                    (stock) => stock.simbolo === optionsTicker,
                )
                // console.log(tickerData[0], 'stockdata')
                setStockPrice(tickerData[0].ultimoPrecio)
                setStats([
                    `$${tickerData[0].apertura}`,
                    tickerData[0].cantidadOperaciones,
                    `$${tickerData[0].maximo}`,
                    `$${tickerData[0].minimo}`,
                    `${tickerData[0].variacionPorcentual}%`,
                    tickerData[0].volumen,
                    tickerData[0].puntas ? tickerData[0].puntas.cantidadCompra : 0,
                    tickerData[0].puntas ? tickerData[0].puntas.cantidadVenta : 0,
                    tickerData[0].puntas ? `$${tickerData[0].puntas.precioCompra}` : 0,
                    tickerData[0].puntas ? `$${tickerData[0].puntas.precioVenta}` : 0,
                ])
            }
        } catch (error) {
            console.log(error)
        }
    }, [stockData, optionsTicker])

    const handleSelectTicker = (e) => {
        setOptionsTicker(e)
        localStorage.setItem('optionsTicker', e)
    }

    const dropDowns = [
        {
            title: 'Ticker',
            items: ['GGAL', 'YPFD', 'PAMP', 'COME'],
            selected: optionsTicker,
            handle: handleSelectTicker,
        },
    ]

    return (
        <Container>
            <H1>Opciones</H1>
            <>
                <ButtonToolbar>
                    <Dropdowns>
                        {dropDowns.map((dropDown, index) => (
                            <DropdownButton
                                key={index}
                                title={`${dropDown.title}: ${dropDown.selected}`}
                                onSelect={dropDown.handle}
                            >
                                {dropDown.items.map((item, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        style={buttonStyle}
                                        eventKey={item}
                                    >
                                        {item}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        ))}
                    </Dropdowns>
                    <Button>
                        {stockPrice ? `Precio: $${stockPrice}` : 'Cargando...'}
                    </Button>
                    <div>
                        {loading ? <Loading height="32" width="32" /> : null}
                    </div>
                </ButtonToolbar>
                <Table>
                    <Tbody>
                        <tr>
                            {stockStatsId.map((statId, index) => (
                                <Td key={index}>{statId}</Td>
                            ))}
                        </tr>
                        <tr>
                            {stats.map((stat, index) => (
                                <Td key={index}>{stat}</Td>
                            ))}
                        </tr>
                    </Tbody>
                </Table>
                <OptionsTable rowData={optionsData} stockPrice={stockPrice} />
            </>
        </Container>
    )
}

export default Options

const gap = '8px'
const backgroundColor = '#1A2534'
const borderRadius = '10px'
const color = '#fff'

const buttonStyle = {
    backgroundColor: '#233245',
    borderRadius: borderRadius,
    color: color,
    padding: '10px',
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${backgroundColor};
    height: 100%;
    width: 100%;
    border-radius: ${borderRadius};
    gap: ${gap};
`

const H1 = styled.h1`
    color: ${color};
    padding: 10px;
`

const Dropdowns = styled.div`
    display: flex;
    gap: ${gap};
`

const ButtonToolbar = styled.div`
    display: flex;
    gap: ${gap};
`

const Table = styled.table`
    color: ${color};
`
const Tbody = styled.tbody`
    text-align: center;
`
const Td = styled.td`
    border: 1px solid ${color};
    padding: 10px;
`
