import React from 'react'
import Layout from './components/Layout/Layout'
import './App.css'

const App = () => {

    return (
        <Layout/>
    )
}

export default App
