import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { calculateStats } from '../../utils'
import content from '../../content/content'

const statsId = content.statsId

const Stats = ({ planData, filteredData, data, initialCapital, risk }) => {

    const [stats, setStats] = useState([0, 0, 0, 0, 0, 0, 0, 0])
    const [statsData, setStatsData] = useState(data)
    
    useEffect(() => {
        setStatsData(data)
        if (filteredData.length === 0) {
            setStats([0, 0, 0, 0, 0, 0, 0, 0])
            return
        } else {
            setStats(calculateStats(filteredData, initialCapital, risk))
        }
    }, [filteredData])

    useEffect(() => {
        setStatsData(planData)
        if (planData.length === 0) {
            setStats([0, 0, 0, 0, 0, 0, 0, 0])
            return
        } else {
            setStats(calculateStats(planData, initialCapital, risk))
        }
    }, [planData])



    useEffect(() => {
        setStats(calculateStats(statsData, initialCapital, risk))
    }, [, initialCapital, risk])

    return (
        <>
            <Table>
                <Tbody>
                    <tr>
                        {statsId.map((statId, index) => (
                            <Td key={index}>{statId}</Td>
                        ))}
                    </tr>
                    <tr>
                        {stats.map((stat, index) => (
                            <Td key={index}>{stat}</Td>
                        ))}
                    </tr>
                </Tbody>
            </Table>
        </>
    )
}
const color = '#fff'

const Table = styled.table`
    color: ${color};
`
const Tbody = styled.tbody`
    text-align: center;
`
const Td = styled.td`
    border: 1px solid ${color};
    padding: 10px;
`

export default Stats
