import React from 'react';
import styled from 'styled-components';

const NotFound = () => {
    return (
        <Center>
            <h1>Not Found</h1>
            <A href="/">Volver a la pagina principal</A>
        </Center>
    );
};

const Center = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #ffffff;
`;

const A = styled.a`
    color: #ffffff;
    text-decoration: none;
    margin-top: 20px;
`;

export default NotFound;