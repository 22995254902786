const calculateStats = (data, ini, r) => {
    const riskPerTrade = (ini * r) / 100
    const totalProfit =
        '$' +
        (
            data.reduce((acc, trade) => acc + parseFloat(trade.RR), 0) *
            riskPerTrade
        ).toFixed(2)
    const trades = data.length
    const winTrades = data.filter((trade) => parseFloat(trade.RR) > 0).length
    // const lossTrades = data.filter((trade) => parseFloat(trade.RR) < 0).length
    const winRate = ((winTrades / trades) * 100).toFixed(2) + '%'
    const totalRR = data
        .reduce((acc, trade) => acc + parseFloat(trade.RR), 0)
        .toFixed(2)
    const pnl = data
        .reduce((acc, trade) => acc + parseFloat(trade.PnL.replace('%', '')), 0)
        .toFixed(2)
    const posRR = data
        .filter((trade) => parseFloat(trade.RR) > 0)
        .reduce((acc, trade) => acc + parseFloat(trade.RR), 0)
        .toFixed(2)
    const negRR = data
        .filter((trade) => parseFloat(trade.RR) < 0)
        .reduce((acc, trade) => acc + parseFloat(trade.RR), 0)
        .toFixed(2)
    const profitFactor = (posRR / Math.abs(negRR)).toFixed(2)
    const avgTrade = (totalRR / trades).toFixed(2)
    const time = Math.abs(data[data.length - 1].timestamp - data[0].timestamp)
    const timeInDays = Math.floor(time / (1000 * 60 * 60 * 24)) + ' days'

    return [
        totalProfit,
        trades,
        winRate,
        profitFactor,
        avgTrade,
        totalRR,
        pnl,
        timeInDays,
    ]
}

const calculateRR = (pnl, strategy) => {
    const stratSL = [4, 4, 3, 8, 5]
    const stratID = strategy.slice(-3)

    switch (stratID) {
        case '101':
            return (parseFloat(pnl.replace('%', '')) / stratSL[0]).toFixed(2)
        case '102':
            return (parseFloat(pnl.replace('%', '')) / stratSL[1]).toFixed(2)
        case '103':
            return (parseFloat(pnl.replace('%', '')) / stratSL[2]).toFixed(2)
        case '104':
            return (parseFloat(pnl.replace('%', '')) / stratSL[3]).toFixed(2)
        default:
            return (parseFloat(pnl.replace('%', '')) / stratSL[4]).toFixed(2)
    }
}

const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/')
    const date = new Date(Number(year), Number(month) - 1, Number(day))
    return date
}

const calcDataPoints = (data) => {
    let dataPoints = []

    for (let i = 0; i < data.length; i++) {
        let sum = 0

        for (let j = 0; j <= i; j++) {
            let rr = data[j].RR.replace('%', '')
            rr = parseFloat(rr)
            sum += rr
        }

        dataPoints.push({
            x: data[i].timestamp,
            y: sum,
        })
    }
    return dataPoints
}

const timeFilter = (data, time) => {
    const now = new Date().getTime()

    let timeInMS
    switch (time) {
        case '1D':
            timeInMS = 86400000
            break
        case '1W':
            timeInMS = 604800000
            break
        case '1M':
            timeInMS = 2628000000
            break
        case '3M':
            timeInMS = 7884000000
            break
        case '6M':
            timeInMS = 15768000000
            break
        case '1Y':
            timeInMS = 31536000000
            break
        default:
            timeInMS = Infinity
    }

    return data.filter((trade) => {
        const tradeTime = getMiliSeconds(trade.timestamp)
        return now - tradeTime < timeInMS
    })
}

const sortData = (data, selectedItemSort, selectedItemOrder) => {
    return data.sort((a, b) => {
        if (selectedItemSort === 'Time') {
            return selectedItemOrder === 'Asc'
                ? a.timestamp - b.timestamp
                : b.timestamp - a.timestamp
        }
    })
}

const filterData = (data, selectedItemFilter, selectedItemTicker) => {
    if (selectedItemFilter === 'All' && selectedItemTicker === 'All') {
        return data
    } else {
        return data.filter((trade) => {
            const filterCondition =
                selectedItemFilter === 'All' ||
                trade.Strategy.includes(selectedItemFilter)
            const tickerCondition =
                selectedItemTicker === 'All' ||
                trade.Ticker === selectedItemTicker
            return filterCondition && tickerCondition
        })
    }
}

const getMiliSeconds = (timestamp) => {
    return timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
}

const getWeek = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
}

const maxLossWeekly = (data, d) => {
    let consecutiveLosses = 0
    let currentWeek = null
    let lastTradeWeek = null
    let filteredData = []

    data.sort((a, b) => getMiliSeconds(b.timestamp) - getMiliSeconds(a.timestamp))

    data.forEach((trade) => {
        const tradeDate = new Date(trade.timestamp)
        const pnl = parseFloat(trade.PnL.replace('%', ''))

        const currentWeekOfYear = getWeek(tradeDate)
        // Comprobar si es el primer trade o si la semana ha cambiado desde el último trade
        if (!lastTradeWeek || currentWeekOfYear !== lastTradeWeek) {
            currentWeek = tradeDate
            consecutiveLosses = 0
            lastTradeWeek = currentWeekOfYear
            console.log('New week', currentWeek)
            console.log('Consecutive losses', consecutiveLosses)
        } else {
            if (pnl < 0) {
                consecutiveLosses++
            }
        }

        if (consecutiveLosses === d) {
            currentWeek = null
        }

        if (currentWeek) {
            console.log('pushing trade', trade)
            filteredData.push(trade)
        }
    })

    console.log(filteredData, d)

    return filteredData
}

const planFilter = (planFilter, data, setData, fullData) => {
    setData(fullData)
    let planFilterData
    switch (planFilter) {
        case 'None':
            planFilterData = fullData
            setData(planFilterData)
            break
        case 'Start losing 2 trades':
            planFilterData = fullData
            setData(planFilterData)
            break
        case '1 loss trades in row':
            planFilterData = maxLossWeekly(data, 1)
            setData(planFilterData)
            break
        case '2 loss trades in row':
            planFilterData = maxLossWeekly(data, 2)
            setData(planFilterData)
            break
        case '3 loss trades in row':
            planFilterData = maxLossWeekly(data, 3)
            setData(planFilterData)
            break
        default:
            setData(data)
    }
    return planFilterData
}

module.exports = {
    calculateStats,
    calcDataPoints,
    sortData,
    filterData,
    getMiliSeconds,
    timeFilter,
    planFilter,
    calculateRR,
    parseDate,
}
