import React from 'react';
import styled from 'styled-components';

const InputValue = ({text, onChange, defaultVal}) => {
    return (
        <Container>
            <Text>{text}</Text>
            <Input type="number" placeholder={defaultVal} onChange={onChange}/>
        </Container>
    );
};


const backgroundColor = '#1A2534'
const borderRadius = '10px'
const layoutColor = '#233245'
const color = '#fff'

const Container = styled.div`
    align-items: center;
    padding: 10px;
    margin: 10px;
    background-color: ${backgroundColor};
    border-radius: ${borderRadius};
`;

const Text = styled.p`
    color: ${color};
`;

const Input = styled.input`
    padding: 10px;
    border-radius: ${borderRadius};
    border: none;
    background-color: ${layoutColor};
    color: ${color};
`;

export default InputValue;