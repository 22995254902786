import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import SignalCards from '../common/SignalCards'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Loading from '../common/Loading'
import { sortData, filterData, getMiliSeconds, timeFilter } from '../../utils'
import axios from 'axios'

const closedTrades = 'closed_trade'
const buySignal = 'buy_signal'

const Signals = () => {
    const [signals, setSignals] = useState([])
    const [uniqueTickers, setUniqueTickers] = useState([])
    const [selectedItemSignal, setSelectedItemSignal] = useState(
        localStorage.getItem('selectedItemSignal') || 'Open Signal',
    )
    const [selectedItemSort, setSelectedItemSort] = useState(
        localStorage.getItem('selectedItemSort') || 'Time',
    )
    const [selectedItemOrder, setSelectedItemOrder] = useState(
        localStorage.getItem('selectedItemOrder') || 'Desc',
    )
    const [selectedItemFilter, setSelectedItemFilter] = useState(
        localStorage.getItem('selectedItemFilter') || 'All',
    )
    const [selectedItemTime, setSelectedItemTime] = useState(
        localStorage.getItem('selectedItemTime') || 'All',
    )

    const [selectedItemTicker, setSelectedItemTicker] = useState(
        localStorage.getItem('selectedItemTicker') || 'All',
    )

    const handleSelectSignal = (eventKey) => {
        setSelectedItemSignal(eventKey)
        localStorage.setItem('selectedItemSignal', eventKey)
    }

    const handleSelectSort = (eventKey) => {
        setSelectedItemSort(eventKey)
        localStorage.setItem('selectedItemSort', eventKey)
    }

    const handleSelectOrder = (eventKey) => {
        setSelectedItemOrder(eventKey)
        localStorage.setItem('selectedOrder', eventKey)
    }

    const handleSelectFilter = (eventKey) => {
        setSelectedItemFilter(eventKey)
        localStorage.setItem('selectedItemFilter', eventKey)
    }

    const handleSelectTime = (eventKey) => {
        setSelectedItemTime(eventKey)
        localStorage.setItem('selectedItemTime', eventKey)
    }

    const handleSelectTicker = (eventKey) => {
        setSelectedItemTicker(eventKey)
        localStorage.setItem('selectedItemTicker', eventKey)
    }

    const handleResetSettings = () => {
        setSelectedItemSignal('Open Signal')
        setSelectedItemSort('Time')
        setSelectedItemOrder('Desc')
        setSelectedItemFilter('All')
        setSelectedItemTime('All')
        setSelectedItemTicker('All')
        localStorage.clear()
    }

    useEffect(() => {
        const fetchData = async () => {

            const buy = await axios
                .get(
                    `https://us-central1-trading-alerts-c1dba.cloudfunctions.net/app/data/${buySignal}`,
                )
                .then((response) => response.data)
                .catch((error) => console.error(error))

            const closed = await axios
                .get(
                    `https://us-central1-trading-alerts-c1dba.cloudfunctions.net/app/data/${closedTrades}`,
                )
                .then((response) => response.data)
                .catch((error) => console.error(error))

            setUniqueTickers(
                buy
                    .reduce((acc, signal) => {
                        if (!acc.includes(signal.Ticker)) {
                            acc.push(signal.Ticker)
                        }
                        return acc
                    }, [])
                    .sort(),
            )

            let lastOpen = {}
            let lastClosed = {}

            buy.forEach((signal) => {
                const ticker = signal.Ticker
                if (
                    !lastOpen[ticker] ||
                    getMiliSeconds(lastOpen[ticker].timestamp) <
                        getMiliSeconds(signal.timestamp)
                ) {
                    lastOpen[ticker] = signal
                }
            })

            closed.forEach((signal) => {
                const ticker = signal.Ticker
                if (
                    !lastClosed[ticker] ||
                    getMiliSeconds(lastClosed[ticker].timestamp) <
                        getMiliSeconds(signal.timestamp)
                ) {
                    lastClosed[ticker] = signal
                }
            })

            const openTrades = []

            Object.keys(lastOpen).forEach((ticker) => {
                if (lastClosed[ticker]) {
                    const openTimestamp = getMiliSeconds(
                        lastOpen[ticker].timestamp,
                    )
                    const closedTimestamp = getMiliSeconds(
                        lastClosed[ticker].timestamp,
                    )

                    if (openTimestamp > closedTimestamp) {
                        openTrades.push(lastOpen[ticker])
                    }
                } else {
                    openTrades.push(lastOpen[ticker])
                }
            })

            setSignals(
                selectedItemSignal === 'Buy Signals'
                    ? timeFilter(
                          filterData(
                              sortData(
                                  buy,
                                  selectedItemSort,
                                  selectedItemOrder,
                              ),
                              selectedItemFilter,
                              selectedItemTicker,
                          ),
                          selectedItemTime,
                      )
                    : selectedItemSignal === 'Closed Signals'
                      ? timeFilter(
                            filterData(
                                sortData(
                                    closed,
                                    selectedItemSort,
                                    selectedItemOrder,
                                ),
                                selectedItemFilter,
                                selectedItemTicker,
                            ),
                            selectedItemTime,
                        )
                      : timeFilter(
                            filterData(
                                sortData(
                                    openTrades,
                                    selectedItemSort,
                                    selectedItemOrder,
                                ),
                                selectedItemFilter,
                                selectedItemTicker,
                            ),
                            selectedItemTime,
                        ),
            )
        }
        fetchData()
    }, [
        selectedItemSignal,
        selectedItemSort,
        selectedItemFilter,
        selectedItemTime,
        selectedItemTicker,
        selectedItemOrder,
    ])

    const dropDowns = [
        {
            title: 'Signals',
            items: ['Open Signals', 'Buy Signals', 'Closed Signals'],
            selected: selectedItemSignal,
            handle: handleSelectSignal,
        },
        {
            title: 'Sort',
            items: ['Time'],
            selected: selectedItemSort,
            handle: handleSelectSort,
        },
        {
            title: 'Order',
            items: ['Asc', 'Desc'],
            selected: selectedItemOrder,
            handle: handleSelectOrder,
        },
        {
            title: 'Filter',
            items: ['All', '101', '102', '103', '104', '105'],
            selected: selectedItemFilter,
            handle: handleSelectFilter,
        },
        {
            title: 'Time',
            items: ['All', '1D', '1W', '1M', '3M', '6M', '1Y'],
            selected: selectedItemTime,
            handle: handleSelectTime,
        },
        {
            title: 'Ticker',
            items: ['All', ...uniqueTickers],
            selected: selectedItemTicker,
            handle: handleSelectTicker,
        },
    ]

    return (
        <Container>
            <H1>Signals</H1>
            {signals.length > 0 ? (
                <>
                    <ButtonToolbar>
                        <Dropdowns>
                            {dropDowns.map((dropDown, index) => (
                                <DropdownButton
                                    key={index}
                                    title={`${dropDown.title}: ${dropDown.selected}`}
                                    onSelect={dropDown.handle}
                                >
                                    {dropDown.items.map((item, index) => (
                                        <Dropdown.Item
                                            key={index}
                                            style={buttonStyle}
                                            eventKey={item}
                                        >
                                            {item}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            ))}
                        </Dropdowns>
                        <Button onClick={handleResetSettings}>
                            Reset Settings
                        </Button>
                    </ButtonToolbar>
                    <Cards className="mt-4">
                        {signals &&
                            signals.map((signal, index) => (
                                <SignalCards
                                    key={index}
                                    data={signal}
                                    selected={selectedItemSignal}
                                />
                            ))}
                    </Cards>
                </>
            ) : (
                <Loading />
            )}
        </Container>
    )
}

const gap = '8px'
const backgroundColor = '#1A2534'
const borderRadius = '10px'
const color = '#fff'

const buttonStyle = {
    backgroundColor: '#233245',
    borderRadius: borderRadius,
    color: color,
    padding: '10px',
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${backgroundColor};
    height: 100%;
    width: 100%;
    border-radius: ${borderRadius};
    gap: ${gap};
`

const H1 = styled.h1`
    color: ${color};
    padding: 10px;
`

const Cards = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: 1fr;
    gap: ${gap};
`

const Dropdowns = styled.div`
    display: flex;
    gap: ${gap};
    flex-wrap: wrap;
`

const ButtonToolbar = styled.div`
    display: flex;
    flex-wrap: wrap
    justify-content: space-between;
    gap: ${gap};
`

export default Signals
