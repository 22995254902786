import React, { useEffect, useState } from 'react'
import useAuth from '../hooks/useAuth'
import styled from 'styled-components'

const Login = () => {
    const { isLoggedIn, error, login, logout } = useAuth()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        login(username, password)
    }

    useEffect(() => {
        if (isLoggedIn) {
            window.location.href = '/dashboard'
        }
    }, [])

    return (
        <Container>
            {!isLoggedIn && (
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Nombre de usuario"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Contraseña"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">Iniciar sesión</button>
                </form>
            )}
            {error && <p>{error}</p>}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    form {
        display: flex;
        flex-direction: column;
        input {
            margin-bottom: 10px;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid #ccc;
        }
        button {
            padding: 10px;
            border-radius: 5px;
            border: none;
            background-color: #007bff;
            color: #fff;
            cursor: pointer;
        }
    }
`

export default Login
