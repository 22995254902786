import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import StocksTable from '../common/StocksTable'
import axios from 'axios'

const Stocks = () => {
    const [stocksData, setStocksData] = useState([])

    useEffect(() => {
        axios
            .get('https://us-central1-trading-alerts-c1dba.cloudfunctions.net/app/cotizaciones')
            .then((response) => {
                setStocksData(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])
    return (
        <Container>
            <H1>Acciones</H1>
            <StocksTable rowData={stocksData.titulos} />
        </Container>
    )
}

const gap = '8px'
const backgroundColor = '#1A2534'
const borderRadius = '10px'
const color = '#fff'


const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${backgroundColor};
    height: 100%;
    width: 100%;
    border-radius: ${borderRadius};
    gap: ${gap};
`

const H1 = styled.h1`
    color: ${color};
    padding: 10px;
`

export default Stocks
