import { useState } from 'react'
import content from '../../content/content'
import { Navigate } from 'react-router-dom'

const useAuth = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('auth') || false)
    const [error, setError] = useState(null)
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    const credentials = content.users.credentials

    const login = async (username, password) => {
        setIsAuthenticating(true)
        try {
            credentials.map((cred) => {
                if (cred.username === username && cred.password === password) {
                    setIsLoggedIn(true)
                    setError(null)
                    localStorage.setItem('auth', true)
                    setIsAuthenticating(false);
                    setTimeout(() => {
                        console.log('isLoggedIn useAuth', isLoggedIn)
                        window.location.reload();
                    }, 1000)
                    return;
                }
            })
        } catch (err) {
            setError('Ocurrió un error al intentar autenticarse')
            setIsAuthenticating(false);
        }
    }

    const logout = () => {
        console.log('logout')
        setIsLoggedIn(false)
        localStorage.removeItem('auth')
        console.log('isLoggedIn useAuth', isLoggedIn)
        window.location.href = '/auth'
    }

    return { isLoggedIn, isAuthenticating, error, login, logout }
}

export default useAuth
