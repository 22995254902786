import logo from '../public/img/ts-logo.svg'
import dashboard from '../public/img/dashboard.svg'
import signals from '../public/img/signals.svg'
import calculator from '../public/img/calculator.svg'
import scanner from '../public/img/scanner.svg'
import candle from '../public/img/candle.svg'
import portfolio from '../public/img/portfolio.svg'
import chart from '../public/img/chart.svg'
import help from '../public/img/help.svg'
import settings from '../public/img/settings.svg'
import logout from '../public/img/logout.svg'
import stocks from '../public/img/stocks.svg'


const content = {
    sidebar: {
        title: 'Trading Solutions',
        logo: logo,
        menu: [
            {
                description: 'MAIN MENU',
                menu: [
                    {
                        title: 'Dashboard',
                        icon: chart,
                        path: '/dashboard',
                    },
                    {
                        title: 'Señales',
                        icon: signals,
                        path: '/signals',
                    },
                    {
                        title: 'Acciones',
                        icon: stocks,
                        path: '/stocks',
                    },
                    {
                        title: 'Opciones',
                        icon: scanner,
                        path: '/options',
                    },
                    {
                        title: 'Calculadoras',
                        icon: calculator,
                        path: '/calculator',
                    },
                    {
                        title: 'Scanner',
                        icon: dashboard,
                        path: '/scanner',
                    },
                    {
                        title: 'Grafico',
                        icon: candle,
                        path: '/chart',
                    },
                    {
                        title: 'Portfolio',
                        icon: portfolio,
                        path: '/portfolio',
                    },
                ],
            },
            {
                description: 'SETTINGS',
                menu: [
                    {
                        title: 'Ayuda y soporte',
                        icon: help,
                        // path: '',
                    },
                    {
                        title: 'Configuracion',
                        icon: settings,
                        // path: '',
                    },
                    {
                        title: 'Cerrar sesion',
                        icon: logout,
                        // path: '',
                    },
                ],
            },
        ],
    },
    statsId: [
        'Total Profit',
        'Trades',
        'Win Rate',
        'Profit Factor',
        'Avg Trade RR',
        'RR',
        'PnL %',
        'Time',
    ],
    stockStatsId: [
        'Apertura',
        'Operaciones',
        'Maximo',
        'Minimo',
        'Variacion %',
        'Volumen',
        'Punta Vol Compra',
        'Punta Vol Venta',
        'Punta $ Compra',
        'Punta $ Precio',
    ],
    users: {
        credentials: [
            {
                username: 'admin',
                password: 'admin',
            },
            {
                username: 'user',
                password: 'user',
            },
            {
                username: 'test',
                password: 'test',
            }
        ]
    }
}

export default content
