import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Chart from '../Section/Chart'
import Table from '../Section/Table'
import StatsTable from '../Section/Stats'
import output from '../../output.json'
import InputValue from '../common/Input'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Loading from '../common/Loading'
import axios from 'axios'

import { calcDataPoints, planFilter, calculateRR, parseDate } from '../../utils'

const oldData = output.previousData

const Dashboard = () => {
    const [rowData, setRowData] = useState([])
    const [options, setOptions] = useState({})
    const gridRef = useRef(null)
    const [filteredData, setFilteredData] = useState([])
    const [planData, setPlanData] = useState([])
    const [selectedItemPlan, setSelectedItemPlan] = useState(
        localStorage.getItem('selectedItemPlan') || 'None',
    )
    const [initialCapital, setInitialCapital] = useState(1000)
    const [risk, setRisk] = useState(1)

    useEffect(() => {
        const fetchData = async () => {
            const dataApi = await axios
                .get(
                    'https://us-central1-trading-alerts-c1dba.cloudfunctions.net/app/data/closed_trade',
                )
                .then((response) => {
                    console.log(response.data, 'response')
                    return response.data
                })
                .catch((error) => {
                    console.log(error)
                })

            const newData = dataApi.map((doc) => {
                const timestamp = doc.timestamp
                const date = timestamp
                    ? new Date(
                          timestamp.seconds * 1000 +
                              timestamp.nanoseconds / 1000000,
                      )
                    : null

                return {
                    ...doc,
                    timestamp: date,
                }
            })

            const oldDataFormatted = oldData.map((data) => {
                const date = parseDate(data.timestamp)
                return {
                    ...data,
                    timestamp: date,
                }
            })

            let data = [...oldDataFormatted, ...newData]

            let updatedData = data.map((row) => ({
                ...row,
                RR: calculateRR(row.PnL, row.Strategy),
            }))

            data = updatedData

            data.sort((a, b) => a.timestamp - b.timestamp)

            const dataPoints = calcDataPoints(data)

            setRowData(data)
            setFilteredData(data)
            setPlanData(data)

            setOptions({
                animationEnabled: true,
                exportEnabled: true,
                theme: 'dark2',
                title: {
                    text: 'RR vs Time',
                },
                axisY: {
                    title: 'RR',
                    suffix: 'r',
                },
                axisX: {
                    title: 'Date',
                    prefix: '',
                    interval: 2,
                },
                data: [
                    {
                        type: 'spline',
                        toolTipContent: 'RR vs Time',
                        dataPoints: dataPoints,
                        color: 'lime',
                    },
                ],
            })
        }

        fetchData()
    }, [])

    useEffect(() => {
        try {
            const newDataPoints = filteredData.sort(
                (a, b) => a.timestamp - b.timestamp,
            )

            const dataPoints = calcDataPoints(newDataPoints)

            setOptions((prevOptions) => ({
                ...prevOptions,
                data: [
                    {
                        type: 'spline',
                        toolTipContent: 'RR vs Time',
                        dataPoints: dataPoints,
                        color: 'lime',
                    },
                ],
            }))
        } catch (error) {
            console.log('error')
        }
    }, [filteredData])

    useEffect(() => {
        try {
            const newDataPoints = planData.sort(
                (a, b) => a.timestamp - b.timestamp,
            )
            const dataPoints = calcDataPoints(newDataPoints)

            setOptions((prevOptions) => ({
                ...prevOptions,
                data: [
                    {
                        type: 'spline',
                        toolTipContent: 'RR vs Time',
                        dataPoints: dataPoints,
                        color: 'lime',
                    },
                ],
            }))
        } catch (error) {
            console.log('error')
        }
    }, [planData])

    const handleCapital = (e) => {
        setInitialCapital(e.target.value)
        if (e.target.value === '') {
            setInitialCapital(0)
        }
    }

    const handleRisk = (e) => {
        setRisk(e.target.value)
        if (e.target.value === '') {
            setRisk(0)
        }
    }

    const handleFilterChanged = () => {
        if (gridRef.current) {
            const filteredData = gridRef.current.api
                .getModel()
                .rowsToDisplay.map((row) => row.data)
            setFilteredData(filteredData)
        }
    }

    const handleSelectPlan = (e) => {
        const planFilterData = planFilter(e, filteredData, setPlanData, rowData)
        setFilteredData(planFilterData)
        setSelectedItemPlan(e)
        localStorage.setItem('selectedItemPlan', e)
    }

    const dropDowns = [
        {
            title: 'Plans',
            items: [
                'None',
                'Start losing 2 trades',
                '1 loss trades in row',
                '2 loss trades in row',
                '3 loss trades in row',
            ],
            selected: selectedItemPlan,
            handle: handleSelectPlan,
        },
    ]

    return (
        <>
            <H1>Dashboard</H1>
            {rowData && rowData.length > 0 ? (
                <Container>
                    <Stats>
                        <SettingsContainer>
                            <InputsContainer>
                                <InputValue
                                    text="Initial Capital $"
                                    onChange={handleCapital}
                                    defaultVal={initialCapital}
                                />
                                <InputValue
                                    text="Risk per Trade %"
                                    onChange={handleRisk}
                                    defaultVal={risk}
                                />
                                <RiskPerTrade>
                                    Risk ${(initialCapital * risk) / 100}
                                </RiskPerTrade>
                            </InputsContainer>
                            <FilterContainer>
                                {dropDowns.map((dropDown, index) => (
                                    <DropdownButton
                                        key={index}
                                        title={`${dropDown.title}: ${dropDown.selected}`}
                                        onSelect={dropDown.handle}
                                    >
                                        {dropDown.items.map((item, index) => (
                                            <Dropdown.Item
                                                key={index}
                                                style={buttonStyle}
                                                eventKey={item}
                                            >
                                                {item}
                                            </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                ))}
                            </FilterContainer>
                        </SettingsContainer>
                        <StatsTable
                            data={rowData}
                            filteredData={filteredData}
                            planData={planData}
                            initialCapital={initialCapital}
                            risk={risk}
                        />
                    </Stats>
                    <ChartsContent>
                        <Chart options={options} />
                    </ChartsContent>
                    <Table
                        gridRef={gridRef}
                        onFilterChanged={handleFilterChanged}
                        rowData={filteredData}
                    />
                </Container>
            ) : (
                <Loading />
            )}
        </>
    )
}

const gap = '8px'
const backgroundColor = '#1A2534'
const borderRadius = '10px'
const color = '#fff'
// const layoutColor = '#233245'

const buttonStyle = {
    backgroundColor: '#233245',
    borderRadius: borderRadius,
    color: color,
    padding: '10px',
}

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 3fr;
    background-color: ${backgroundColor};
    height: 100%;
    width: 100%;
    border-radius: ${borderRadius};
    gap: ${gap};
`

const H1 = styled.h1`
    color: ${color};
    padding: 10px;
    height: auto;
`

const Stats = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    border-radius: ${borderRadius};
    gap: ${gap};
`

const ChartsContent = styled.div`
    display: flex;
    background-color: ${backgroundColor};
    height: 100%;
    width: 100%;
    border-radius: ${borderRadius};
    gap: ${gap};
`

const SettingsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const FilterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${gap};
    flex-wrap: wrap;
`

const InputsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    gap: ${gap};
    flex-wrap: wrap;
`

const RiskPerTrade = styled.div`
    color: ${color};
    padding: 10px;
    margin: 10px;
`

export default Dashboard
