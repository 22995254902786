import React from 'react'
import styled from 'styled-components'
import content from '../../content/content'
import useAuth from '../hooks/useAuth'
import HeaderMenu from '../common/HeaderMenu.jsx'

const contentSidebar = content.sidebar

const Sidebar = ({ handleSideBar, show }) => {
    const { logout } = useAuth()

    // const handleCloseSession = () => {
    //     // e.preventDefault()
    //     // if (e.title === 'Cerrar sesion') {
    //     //     logout()
    //     // }
    //     console.log('ealgo')
    // }

    return (
        <SidebarMenu show={show}>
            <div className="d-flex align-items-center w-100">
                <HeaderMenu onClick={handleSideBar} />
            </div>
            <Menus>
                {contentSidebar.menu.map((item, index) => (
                    <div key={index}>
                        <Subtitle>{item.description}</Subtitle>
                        <List>
                            {item.menu.map((item, index) => (
                                <Item key={index}>
                                    {item.path ? (
                                        <SidebarMenuItem href={item.path}>
                                            <SidebarMenuItemIcon
                                                src={item.icon}
                                            />
                                            <SidebarMenuItemTitle>
                                                {item.title}
                                            </SidebarMenuItemTitle>
                                        </SidebarMenuItem>
                                    ) : (
                                        <SidebarMenuItem>
                                            <SidebarMenuItemIcon
                                                src={item.icon}
                                            />
                                            <SidebarMenuItemTitle
                                                onClick={() =>
                                                    index === 2 && logout()
                                                }
                                            >
                                                {item.title}
                                            </SidebarMenuItemTitle>
                                        </SidebarMenuItem>
                                    )}
                                </Item>
                            ))}
                        </List>
                    </div>
                ))}
            </Menus>
        </SidebarMenu>
    )
}

const SidebarMenu = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    color: #ffffff;

    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100vw;
        height: 100vh;
        background-color: #233245;
        border-radius: 0;
        transform: ${({ show }) =>
            show ? 'translateX(0)' : 'translateX(-100%)'};
        transition: transform 0.3s ease-in-out;

        justify-content: flex-start;
    }
`
// display: ${({ show }) => (show ? 'flex' : 'none')};

const Subtitle = styled.p`
    display: flex;
    width: 240px;
    padding: 10px;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    letter-spacing: 0.4px;
`

const Menus = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    height: 100%;
    width: 100%;
    padding: 0 50px;
`

const List = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    width: 248px;
    padding: 10px 16px;
    gap: 10px;
`

const Item = styled.li`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
`

const SidebarMenuItem = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    cursor: pointer;
    transition: all 0.3s ease;
    gap: 10px;
    text-decoration: none;
    color: inherit;
    text-align: left;
`

const SidebarMenuItemIcon = styled.img`
    width: 20px;
    height: 20px;
`

const SidebarMenuItemTitle = styled.h5`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    letter-spacing: 0.4px;
    padding: 0;
    margin: 0;
`

export default Sidebar
