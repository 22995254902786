import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import CanvasJSReact from '@canvasjs/react-charts'

const AddItem = ({ ticker, optionsData, loading }) => {
    const [itemType, setItemType] = useState('')
    const [Strike, setStrike] = useState('')
    const [stockPrice, setStockPrice] = useState('')
    const [chartData, setChartData] = useState({})
    const [chartOptions, setChartOptions] = useState({})
    const [items, setItems] = useState(() => {
        const savedItems = localStorage.getItem('items')
        return savedItems ? JSON.parse(savedItems) : []
    })

    const [selectedItemVenc, setSelectedItemVenc] = useState('')

    const calculatePayoff = (tipo, strike, prima, precioActivo) => {
        let payoff = 0

        if (tipo === 'Compra Accion') {
            payoff = precioActivo - strike
        } else if (tipo === 'Compra Call') {
            payoff = Math.max(precioActivo - strike, 0) - prima
        } else if (tipo === 'Venta Call') {
            payoff = Math.max(precioActivo - strike, 0) + prima
        } else if (tipo === 'Compra Put') {
            payoff = Math.max(strike - precioActivo, 0) - prima
        } else if (tipo === 'Venta Put') {
            payoff = Math.max(strike - precioActivo, 0) + prima
        }
        return payoff * 100
    }

    const generateXdata = (min, max, step) => {
        const data = []
        for (let i = min; i <= max; i += step) {
            data.push(i)
        }
        return data
    }

    const generateChartData = () => {
        // const preciosActivos = [
        //     0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000,
        // ]

        const preciosActivos = generateXdata(0, 5000, 100)
        const tipoContratos = localStorage.getItem('items')
            ? JSON.parse(localStorage.getItem('items'))
            : []

        try {
            if (tipoContratos.length > 0) {
                const combinedDataPoints = preciosActivos.map((precio) => {
                    let totalPayoff = 0;
    
                    tipoContratos.forEach((tipo) => {
                        const payoff = calculatePayoff(
                            tipo.type,
                            tipo.details,
                            parseFloat(tipo.prima),
                            precio,
                        );
                        console.log(payoff, 'payoff');
                        totalPayoff += payoff;
                    });
    
                    return {
                        x: parseFloat(precio),
                        y: parseFloat(totalPayoff.toFixed(2)),
                    };
                });
    
                const dataset = {
                    type: 'line',
                    name: 'Combined Values',
                    color: 'lime',
                    dataPoints: combinedDataPoints,
                };
    
                setChartData([dataset]); // Puedes pasar un solo conjunto de datos ahora
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSelectVenc = (event) => {
        setSelectedItemVenc(event.target.value)
    }

    const handleChange = (e) => {
        setItemType(e.target.value)
    }

    const handleDetailsChange = (e) => {
        setStrike(parseFloat(e.target.value))
    }

    const handleStockChange = (e) => {
        setStockPrice(parseFloat(e.target.value))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (loading) {
            alert('Los datos no han sido cargados')
            return
        }

        if (
            itemType &&
            Strike &&
            selectedItemVenc &&
            itemType !== 'Compra Accion'
        ) {
            let tipo = ''
            let operacion = ''
            let mes = ''

            if (itemType === 'Compra Call' || itemType === 'Compra Put') {
                operacion = 'Compra'
            } else if (itemType === 'Venta Call' || itemType === 'Venta Put') {
                operacion = 'Venta'
            } else if (itemType === 'Compra Accion') {
                operacion = 'Compra'
            }

            if (itemType === 'Compra Call' || itemType === 'Venta Call') {
                tipo = 'Call'
            } else if (itemType === 'Compra Put' || itemType === 'Venta Put') {
                tipo = 'Put'
            } else if (itemType === 'Compra Accion') {
                tipo = 'Accion'
            }

            mes = (selectedItemVenc) => {
                switch (selectedItemVenc) {
                    case 'Enero':
                        return 'EN'
                    case 'Febrero':
                        return 'FE'
                    case 'Marzo':
                        return 'MR'
                    case 'Abril':
                        return 'AB'
                    case 'Mayo':
                        return 'MY'
                    case 'Junio':
                        return 'JU'
                    case 'Julio':
                        return 'JL'
                    case 'Agosto':
                        return 'AG'
                    default:
                        return ''
                }
            }

            const contractData = optionsData.find((option) => {
                if (
                    option.tipoOpcion === tipo &&
                    option.strikePrice === Strike &&
                    option.cotizacion.ultimoPrecio &&
                    option.simbolo.slice(-2) === mes(selectedItemVenc)
                ) {
                    // console.log(option)
                    return option
                }
            })

            if (!contractData) {
                alert('No se encontraron datos para el contrato ingresado')
                return
            } else {
                // console.log(contractData)
            }

            const newItem = {
                id: Date.now(),
                type: itemType,
                details: Strike,
                contrato: contractData.simbolo,
                ticker: ticker,
                prima: contractData.cotizacion.ultimoPrecio,
                fecha: contractData.fechaVencimiento,
                resultado:
                    operacion === 'Compra'
                        ? `$${-contractData.cotizacion.ultimoPrecio * 100}`
                        : `$${contractData.cotizacion.ultimoPrecio * 100}`,
            }

            setItems([...items, newItem])
            localStorage.setItem('items', JSON.stringify([...items, newItem]))

            // Limpiar el formulario
            setItemType('')
            setStrike('')
            setSelectedItemVenc('')
            setStockPrice('')
        } else if (itemType === 'Compra Accion' && stockPrice) {
            const newItem = {
                id: Date.now(),
                type: itemType,
                details: stockPrice,
                contrato: 'Accion',
                ticker: ticker,
                prima: '0',
                fecha: 'N/A',
                resultado: `$${-stockPrice * 100}`,
            }

            setItems([...items, newItem])
            localStorage.setItem('items', JSON.stringify([...items, newItem]))

            // Limpiar el formulario
            setItemType('')
            setStrike('')
            setSelectedItemVenc('')
            setStockPrice('')
        } else {
            alert('Por favor complete todos los campos')
        }
    }

    const optionsDataId = [
        'Operacion',
        'Ticker',
        'Contrato',
        'Strike / Precio',
        'Prima',
        'Fecha Vencimiento',
        'Resultado',
    ]

    useEffect(() => {
        generateChartData()
    }, [items])

    useEffect(() => {}, [optionsData])

    useEffect(() => {
        const options = {
            animationEnabled: true,
            exportEnabled: true,
            theme: 'dark2',
            title: {
                text: 'Valor a vencimiento',
            },
            axisX: {
                title: 'Precio del activo',
            },
            axisY: {
                title: 'Payoff',
            },
            toolTip: {
                shared: true,
            },
            legend: {
                cursor: 'pointer',
                verticalAlign: 'top',
                horizontalAlign: 'center',
                dockInsidePlotArea: true,
            },
            data: chartData,
        }

        console.log(chartData, 'chartData')

        setChartOptions(options)
    }, [chartData])

    return (
        <Container>
            <form onSubmit={handleSubmit}>
                <Label>Tipo de Contrato:</Label>
                <Select value={itemType} onChange={handleChange}>
                    <option value="">Seleccionar tipo</option>
                    <option value="Compra Call">Comprar Call</option>
                    <option value="Venta Call">Vender Call</option>
                    <option value="Compra Put">Comprar Put</option>
                    <option value="Venta Put">Vender Put</option>
                    <option value="Compra Accion">Comprar Acción</option>
                </Select>
                {itemType !== 'Compra Accion' ? (
                    <>
                        <Label>Vencimiento:</Label>
                        <Select
                            value={selectedItemVenc}
                            onChange={handleSelectVenc}
                        >
                            <option value="">Seleccionar Vto</option>
                            <option value="Abril">Abril</option>
                            <option value="Mayo">Mayo</option>
                            <option value="Junio">Junio</option>
                            <option value="Julio">Julio</option>
                            <option value="Agosto">Agosto</option>
                        </Select>
                        <Label>Strike Price:</Label>
                        <Input
                            type="text"
                            value={Strike}
                            onChange={handleDetailsChange}
                        />
                    </>
                ) : (
                    <>
                        <Label>Stock Price:</Label>
                        <Input
                            type="text"
                            value={stockPrice}
                            onChange={handleStockChange}
                        />
                    </>
                )}

                <Button style={buttonStyle} type="submit">
                    Agregar
                </Button>
            </form>
            <Table>
                <Tbody>
                    <tr>
                        {optionsDataId.map((statId, index) => (
                            <Td key={index}>{statId}</Td>
                        ))}
                    </tr>
                    {items.map((item) => (
                        <tr key={item.id}>
                            <Td>{item.type}</Td>
                            <Td>{item.ticker}</Td>
                            <Td>{item.contrato}</Td>
                            <Td>{item.details}</Td>
                            <Td>{item.prima}</Td>
                            <Td>{item.fecha}</Td>
                            <Td>{item.resultado}</Td>
                        </tr>
                    ))}
                    {items.length > 0 && (
                        <tr>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td>Total</Td>
                            <Td>
                                $
                                {items.reduce(
                                    (acc, item) =>
                                        acc +
                                        parseFloat(
                                            item.resultado.replace('$', ''),
                                        ),
                                    0,
                                )}
                            </Td>
                        </tr>
                    )}
                </Tbody>
            </Table>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '800px',
                    minWidth: '600px',
                }}
            >
                <CanvasJSReact.CanvasJSChart options={chartOptions} />
            </div>
        </Container>
    )
}

const gap = '8px'
const backgroundColor = '#1A2534'
const borderRadius = '10px'
const color = '#fff'
const layoutColor = '#233245'

const buttonStyle = {
    backgroundColor: '#233245',
    borderRadius: borderRadius,
    color: color,
    padding: '10px',
    marginLeft: '10px',
}

const Container = styled.div`
    align-items: center;
    padding: 10px;
    margin: 10px;
    background-color: ${backgroundColor};
    border-radius: ${borderRadius};
`

const Label = styled.label`
    color: ${color};
    margin-right: 20px;
`

const Input = styled.input`
    padding: 10px;
    border-radius: ${borderRadius};
    border: none;
    background-color: ${layoutColor};
    color: ${color};
    margin-right: 20px;
    outline: none;
`

const Select = styled.select`
    padding: 10px;
    border-radius: ${borderRadius};
    border: none;
    background-color: ${layoutColor};
    color: ${color};
    margin-right: 20px;
`

const Table = styled.table`
    margin: 20px 0;
    color: ${color};
`
const Tbody = styled.tbody`
    text-align: center;
`
const Td = styled.td`
    border: 1px solid ${color};
    padding: 10px;
`

export default AddItem
