import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import 'ag-grid-enterprise'

const Table = ({ rowData }) => {
    const [getRowData, setRowData] = useState(rowData)

    useEffect(() => {

        try {

            if (rowData === undefined ) return

            const updatedRowData = rowData.map((row) => {
    
                const ultimoPrecio = `$${row.ultimoPrecio}`
                const variacionPorcentual = `${row.variacionPorcentual}%`
                const maximo = `$${row.maximo}`
                const minimo = `$${row.minimo}`
    
                return {
                    ...row,
                    ultimoPrecio: ultimoPrecio,
                    variacionPorcentual: variacionPorcentual,
                    maximo: maximo,
                    minimo: minimo,
                }
            })
    
            setRowData(updatedRowData)
        } catch (error) {
            console.log(error)
        }

        
    }, [rowData])

    const gridOptions = {
        columnDefs: [
            {
                headerName: 'Ticker',
                field: 'simbolo',
                filter: true,
                sortable: true,
                maxWidth: 200,
            },
            {
                headerName: 'Precio',
                field: 'ultimoPrecio',
                filter: true,
                sortable: true,
                maxWidth: 150,
            },
            {
                headerName: 'Variación',
                field: 'variacionPorcentual',
                filter: true,
                sortable: true,
                maxWidth: 150,
            },
            {
                headerName: 'Volumen',
                field: 'volumen',
                filter: true,
                sortable: true,
                maxWidth: 150,
            },
            {
                headerName: 'Maximo',
                field: 'maximo',
                filter: true,
                sortable: true,
                maxWidth: 300,
            },
            {
                headerName: 'Minimo',
                field: 'minimo',
                filter: true,
                sortable: true,
                maxWidth: 150,
                sort: 'desc',
            },
        ],
        defaultColDef: {
            sortable: true,
            filter: 'agTextColumnFilter',
            editable: true,
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
        },
        suppressRowClickSelection: true,
        rowSelection: 'multiple',
        animateRows: true,
        pagination: false,
        paginationPageSize: 100,
        rowModelType: 'clientSide',
    }

    return (
        <div
            className="ag-theme-quartz-dark"
            style={{ height: '100%', width: '100%', minHeight: '500px' }}
        >
            <AgGridReact
                rowData={getRowData}
                gridOptions={gridOptions}
                // ref={gridRef}
                // onFilterChanged={onFilterChanged}
            />
        </div>
    )
}

export default Table