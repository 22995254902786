import React from 'react'
import styled from 'styled-components'
import content from '../../content/content'
import BurgerMenu from '../common/BurgerMenu.jsx'

const contentSidebar = content.sidebar

const HeaderMenu = ({ onClick }) => {
    return (
        <HeaderContainer>
            <Title>
                <Logo src={contentSidebar.logo} alt="logo" href="/" />
                {contentSidebar.title}
            </Title>
            <BurgerMenu onClick={onClick} />
        </HeaderContainer>
    )
}

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 16px;
    height: 56px;
`

const Logo = styled.img`
    width: 32px;
    height: 32px;
`

const Title = styled.a`
    display: flex;
    height: 56px;
    padding: 0px 16px;
    font-size: 18px;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    color: #ffffff;
`

export default HeaderMenu
