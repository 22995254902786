import React, { useEffect, useRef, memo } from 'react'
import styled from 'styled-components'

function TradingViewWidget() {
    const container = useRef()

    useEffect(() => {
        const script = document.createElement('script')
        script.src =
            'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js'
        script.type = 'text/javascript'
        script.async = true
        script.innerHTML = `
        {
          "autosize": true,
          "symbol": "NASDAQ:GGAL",
          "interval": "D",
          "timezone": "America/Argentina/Buenos_Aires",
          "theme": "dark",
          "style": "1",
          "locale": "es",
          "enable_publishing": false,
          "backgroundColor": "rgba(0, 0, 0, 1)",
          "gridColor": "rgba(255, 255, 255, 0)",
          "withdateranges": true,
          "allow_symbol_change": true,
          "details": true,
          "calendar": true,
          "support_host": "https://www.tradingview.com"
        }`
        container.current.appendChild(script)
    }, [])

    return (
        <>
            <H1>Gráfico</H1>
            <div
                className="tradingview-widget-container"
                ref={container}
                style={{ height: '100%', width: '100%' }}
            >
                <div
                    className="tradingview-widget-container__widget"
                    style={{ height: 'calc(100% - 80px)', width: '100%' }}
                ></div>
                <div className="tradingview-widget-copyright"></div>
            </div>
        </>
    )
}

const gap = '8px'
const backgroundColor = '#1A2534'
const borderRadius = '10px'
const color = '#fff'

const H1 = styled.h1`
    color: ${color};
    padding: 10px;
`

export default memo(TradingViewWidget)
