import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import 'ag-grid-enterprise'

const Table = ({ rowData, stockPrice }) => {
    const [getRowData, setRowData] = useState(rowData)

    useEffect(() => {
        try {
            const updatedRowData = rowData.map((row) => {
                const underlyingPrice = stockPrice
                const strikePrice = parseFloat(row.simbolo.substring(4, 8))
                const type = row.tipoOpcion
                let status

                const dif = Math.abs(
                    ((underlyingPrice - strikePrice) / strikePrice) * 100,
                )
                const maxDiff = 2

                if (type === 'Call') {
                    if (underlyingPrice === 0 || strikePrice === 0) {
                        status = '-'
                    } else if (dif < maxDiff) {
                        status = 'ATM'
                    } else if (underlyingPrice < strikePrice) {
                        status = 'OTM'
                    } else if (underlyingPrice > strikePrice) {
                        status = 'ITM'
                    } else {
                        status = '-'
                    }
                } else if (type === 'Put') {
                    if (underlyingPrice === 0 || strikePrice === 0) {
                        status = '-'
                    } else if (dif < maxDiff) {
                        status = 'ATM'
                    } else if (underlyingPrice < strikePrice) {
                        status = 'ITM'
                    } else if (underlyingPrice > strikePrice) {
                        status = 'OTM'
                    } else {
                        status = '-'
                    }
                }

                const fecha = new Date(row.fechaVencimiento)

                const options = { month: 'long', day: 'numeric' }
                const formattedDate = fecha.toLocaleDateString('es-ES', options)

                let intrinsicValue = 0
                if (type === 'Call') {
                    intrinsicValue = Math.max(0, underlyingPrice - strikePrice)
                } else if (type === 'Put') {
                    intrinsicValue = Math.max(0, strikePrice - underlyingPrice)
                }

                const extrinsicValue = parseFloat(
                    row.cotizacion.ultimoPrecio - intrinsicValue,
                ).toFixed(2)

                return {
                    ...row,
                    status: status,
                    strikePrice: strikePrice,
                    fechaVencimiento: formattedDate,
                    vi: intrinsicValue,
                    ve: extrinsicValue,
                }
            })

            setRowData(updatedRowData)
        } catch (error) {
            console.log(error)
        }
    }, [rowData, stockPrice])

    const gridOptions = {
        columnDefs: [
            {
                headerName: 'Contrato',
                field: 'simbolo',
                filter: true,
                sortable: true,
                maxWidth: 200,
            },
            {
                headerName: 'Tipo',
                field: 'tipoOpcion',
                filter: true,
                sortable: true,
                maxWidth: 150,
            },
            {
                headerName: 'Strike',
                field: 'strikePrice',
                filter: true,
                sortable: true,
                maxWidth: 150,
            },
            {
                headerName: 'Ultimo Precio',
                field: 'cotizacion.ultimoPrecio',
                filter: true,
                sortable: true,
                maxWidth: 150,
            },
            {
                headerName: 'Variación',
                field: 'cotizacion.variacion',
                filter: true,
                sortable: true,
                maxWidth: 150,
            },
            {
                headerName: 'Vencimiento',
                field: 'fechaVencimiento',
                filter: true,
                sortable: true,
                maxWidth: 300,
            },
            {
                headerName: 'Status',
                field: 'status',
                filter: true,
                sortable: true,
                maxWidth: 150,
                sort: 'desc',
            },
            {
                headerName: 'VI',
                field: 'vi',
                filter: true,
                sortable: true,
                maxWidth: 150,
                sort: 'desc',
            },
            {
                headerName: 'VE',
                field: 've',
                filter: true,
                sortable: true,
                maxWidth: 150,
                sort: 'desc',
            },
        ],
        defaultColDef: {
            sortable: true,
            filter: 'agTextColumnFilter',
            editable: true,
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
        },
        suppressRowClickSelection: true,
        rowSelection: 'multiple',
        animateRows: true,
        pagination: false,
        paginationPageSize: 100,
        rowModelType: 'clientSide',
    }

    return (
        <div
            className="ag-theme-quartz-dark"
            style={{ height: '100%', width: '100%', minHeight: '500px' }}
        >
            <AgGridReact
                rowData={getRowData}
                gridOptions={gridOptions}
                // ref={gridRef}
                // onFilterChanged={onFilterChanged}
            />
        </div>
    )
}

export default Table
