import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import AddItem from '../common/AddItem'
import Loading from '../common/Loading'

const Calculadora = () => {
    const [optionsTicker, setOptionsTicker] = useState(
        localStorage.getItem('optionsTicker') || 'GGAL',
    )
    const [selectedItemOptionsStrat, setSelectedItemOptionsStrat] = useState(
        localStorage.getItem('selectedItemOptionsStrat') || 'Married Put',
    )

    const [optionsData, setOptionsData] = useState([])
    const [updatedData, setUpdatedData] = useState([])
    const [stockData, setStockData] = useState([])
    const [stockPrice, setStockPrice] = useState()
    const [loading, setLoading] = useState(true)

    const handleSelectTicker = (e) => {
        setOptionsTicker(e)
        localStorage.setItem('optionsTicker', e)
    }

    const handleSelectOptionsStrat = (event) => {
        setSelectedItemOptionsStrat(event)
        localStorage.setItem('selectedItemOptionsStrat', event)
    }

    const dropDowns = [
        {
            title: 'Estrategia',
            items: [
                'Custom',
                'Married Put',
                'Lanzamiento cubierto Call',
                'Lanzamiento cubierto Put',
                'Stradle',
                'Bull Spread',
                'Bear Spread',
                'Collar',
            ],
            selected: selectedItemOptionsStrat,
            handle: handleSelectOptionsStrat,
        },
        {
            title: 'Ticker',
            items: ['GGAL', 'YPFD', 'PAMP', 'COME'],
            selected: optionsTicker,
            handle: handleSelectTicker,
        },
    ]

    useEffect(() => {
        setLoading(true)
        try {
            axios
                .get(
                    `https://us-central1-trading-alerts-c1dba.cloudfunctions.net/app/options/${optionsTicker}`,
                )
                .then((response) => {
                    setOptionsData(response.data)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                })
            axios
                .get(
                    'https://us-central1-trading-alerts-c1dba.cloudfunctions.net/app/cotizaciones/',
                )
                .then((response) => {
                    setStockData(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }, [])

    useEffect(() => {
        try {
            if (stockData.titulos) {
                const tickerData = stockData.titulos.filter(
                    (stock) => stock.simbolo === optionsTicker,
                )
                setStockPrice(tickerData[0].ultimoPrecio)
            }
        } catch (error) {
            console.log(error)
        }
    }, [stockData, optionsTicker])

    useEffect(() => {
        const updatedRowData = optionsData.map((row) => {
            const strikePrice = parseFloat(row.simbolo.substring(4, 8))

            const fecha = new Date(row.fechaVencimiento)

            const options = { month: 'long', day: 'numeric' }
            const formattedDate = fecha.toLocaleDateString('es-ES', options)

            return {
                ...row,
                // status: status,
                strikePrice: strikePrice,
                fechaVencimiento: formattedDate,
            }
        })

        setUpdatedData(updatedRowData)
    }, [optionsData])

    useEffect(() => {}, [updatedData])

    return (
        <>
            <Container>
                <H1>Calculadora</H1>
                <ButtonToolbar>
                    <Dropdowns>
                        {dropDowns.map((dropDown, index) => (
                            <DropdownButton
                                key={index}
                                title={`${dropDown.title}: ${dropDown.selected}`}
                                onSelect={dropDown.handle}
                            >
                                {dropDown.items.map((item, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        style={buttonStyle}
                                        eventKey={item}
                                    >
                                        {item}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        ))}
                    </Dropdowns>
                    <Button>
                        {stockPrice ? `Precio: $${stockPrice}` : 'Cargando...'}
                    </Button>
                    <div>
                        {loading ? <Loading height="32" width="32" /> : null}
                    </div>
                </ButtonToolbar>
                <AddItem
                    ticker={optionsTicker}
                    optionsData={updatedData}
                    loading={loading}
                />
            </Container>
        </>
    )
}

export default Calculadora

const gap = '8px'
const backgroundColor = '#1A2534'
const borderRadius = '10px'
const color = '#fff'

const buttonStyle = {
    backgroundColor: '#233245',
    borderRadius: borderRadius,
    color: color,
    padding: '10px',
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${backgroundColor};
    height: 100%;
    width: 100%;
    border-radius: ${borderRadius};
    gap: ${gap};
`

const H1 = styled.h1`
    color: ${color};
    padding: 10px;
`

const Dropdowns = styled.div`
    display: flex;
    gap: ${gap};
    flex-wrap: wrap;
`

const ButtonToolbar = styled.div`
    display: flex;
    flex-wrap: wrap
    justify-content: space-between;
    gap: ${gap};
`
