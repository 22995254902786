import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const SignalCards = ({ data, selected }) => {
    const [datos, setDatos] = useState({})

    const milliseconds =
        data.timestamp.seconds * 1000 + data.timestamp.nanoseconds / 1000000

    useEffect(() => {
        if (selected === 'Buy Signals' || selected === 'Open Signals') {
            setDatos({
                ticker: data.Ticker,
                strategy: data.Strategy,
                buyZone: data['Buy Zone'],
                targets: [data.T1, data.T2, data.T3, data.T4, data.T5, data.T6],
                stopLoss: data['Stop Loss'],
                timeframe: data.Timeframe,
                time: new Date(milliseconds),
            })
        } else {
            setDatos({
                ticker: data.Ticker,
                strategy: data.Strategy,
                type: data['Tipo de cierre'],
                pnl: data.PnL,
                duration: data.Time,
                timeframe: data.Timeframe,
                time: new Date(milliseconds),
            })
        }
    }, [data, selected])

    const { ticker, strategy, buyZone, targets, stopLoss, timeframe, time, type, pnl, duration } =
        datos

    return (
        <CardContainer>
            {selected === 'Buy Signals' || selected === 'Open Signals' ? (
                <>
                    <h3>{ticker}</h3>
                    <p>Strategy: {strategy}</p>
                    {buyZone && <p>Buy Zone: {buyZone}</p>}
                    {targets && (
                        <div>
                            <p>Targets:</p>
                            <ul>
                                {targets.map((target, index) => (
                                    <li key={index}>
                                        T{index + 1}: {target}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {stopLoss && <p>Stop Loss: {stopLoss}</p>}
                    <p>Timeframe: {timeframe}</p>
                    {time && <p>{time.toLocaleString()}</p>}
                </>
            ) : (
                <>
                    <h3>{ticker}</h3>
                    <p>Strategy: {strategy}</p>
                    {buyZone && <p>Buy Zone: {buyZone}</p>}
                    {type && <p>Type: {type}</p>}
                    {pnl && <p>PnL: {pnl}</p>}
                    {duration && <p>Duration: {duration}</p>}
                    <p>Timeframe: {timeframe}</p>
                    {time && <p>{time.toLocaleString()}</p>}
                </>
            )}
        </CardContainer>
    )
}

const gap = '8px'
const backgroundColor = '#233245'
const borderRadius = '10px'
const color = '#fff'

const CardContainer = styled.div`
    background-color: ${backgroundColor};
    border-radius: ${borderRadius};
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    color: ${color};
`

export default SignalCards
