import React from 'react'
import CanvasJSReact from '@canvasjs/react-charts'

var CanvasJSChart = CanvasJSReact.CanvasJSChart

const Chart = ({ options }) => {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <CanvasJSChart options={options} />
        </div>
    )
}

export default Chart
