import React from 'react';
import styled from 'styled-components';

const Scanner = () => {
    return (
        <>
            <H1>Scanner</H1>
        </>
    );
};

export default Scanner;


const gap = '8px'
const backgroundColor = '#1A2534'
const borderRadius = '10px'
const color = '#fff'


const H1 = styled.h1`
    color: ${color};
    padding: 10px;
`